// @flow
import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import ArrowLink from "../components/ArrowLink";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import ImageCard from "../components/ImageCard";
import Page from "../components/Page";
import Section from "../components/Section";
import CheckMark from "../icons/Check";
import Heart from "../icons/Heart";
import { query, MEDIUM_BREAKPOINT } from "../style";

import type { ImageSharp, ImageType } from "../types.js";

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType,
    teamImage: ImageType
  }
};
const pWidth = 500;

export default class Index extends React.Component<Props> {
  render() {
    const { mainImage, secondImage, thirdImage, teamImage } = this.props.data;

    return (
      <Page title="Our Philosophy of Care">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Our philosophy of care"
          subtitle="We started the Spine Institute of Idaho to get our patients the care they deserve, and the care that will make them better. To accomplish that goal, we had to address the problems we saw with other spine care options."
          cta="Make an appointment"
        />
        <StyledSection color="#e0ecfa">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem"
            }}
          >
            <Heart />
            <Title>What sets us apart</Title>
          </div>
          <Items>
            <Item>
              <TitleWrap>
                <CheckMark />
                <Head>Multidisciplinary treatment</Head>
              </TitleWrap>
              <Body>
                We are unique in the Treasure Valley in the variety of
                treatments we offer beyond just surgery. We do everything from
                Physical Medicine, Acupuncture and Physiatry, to Physical
                Therapy, and we have a variety of pain management options. Why?
                Because we don't want to be biased towards a single type of
                care, instead tailoring what we do to each patient's unique
                needs.
              </Body>
            </Item>
            <Item>
              <TitleWrap>
                <CheckMark odd />
                <Head>Emphasis on diagnosis</Head>
              </TitleWrap>
              <Body>
                We are dedicated to making an accurate diagnosis. Too often we
                have seen patients who were treated by other doctors without
                fully understanding the cause of their symptoms. Our doctors
                have the modern imaging and diagnostic techniques in house,
                because making an accurate diagnosis is so incredibly important
                to our mission.
              </Body>
            </Item>
            <Item>
              <TitleWrap>
                <CheckMark odd />
                <Head>We care</Head>
              </TitleWrap>
              <Body>
                Our practice is built on trust and empathy, we are committed to
                setting you up for success and alleviating any fears or
                concerns. You can expect us to spend as much time as needed
                discussing your symptoms, exploring possible diagnosises and
                treatment options, and answering any questions you may have.
              </Body>
            </Item>
            <Item>
              <TitleWrap>
                <CheckMark />
                <Head>Personalized treatment</Head>
              </TitleWrap>
              <Body>
                Too often, patients are treated as a number and not an
                individual. We pride ourselves on not just knowing your symptoms
                and conditions, but also, who you are, what you love and what’s
                important to you. We strongly feel that better outcomes happen
                when we know and understand the unique individuals who see us
                for help.
              </Body>
            </Item>
            <Item>
              <TitleWrap>
                <CheckMark />
                <Head>Specialized on the spine</Head>
              </TitleWrap>
              <Body>
                Our focus is spine care, and everything we do revolves around
                that. We have a single focus, we're specialists, and we're good
                at what we do. It is what each of our doctors and practitioners
                lives and breathes, so when it is spine trouble you're having,
                you know who to call.
              </Body>
            </Item>
          </Items>
        </StyledSection>
        <Section skewed={false} noBackground={true}>
          <h1 style={{ textAlign: "center" }}>Meet our team</h1>
          <p style={{ maxWidth: 500, textAlign: "center", margin: "auto" }}>
            Our physicians and staff are experienced and professional, but we
            know it’s not just about that. You can trust us to care and treat
            your individual needs.
          </p>
          <ImageCard
            style={{ margin: "20px auto", maxWidth: 570 }}
            image={teamImage.childImageSharp}
          />
          <ArrowLink
            style={{ maxWidth: 500, margin: " 50px auto", display: "flex" }}
            to="/team"
          >
            More about us
          </ArrowLink>
        </Section>
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const StyledSection = styled(Section)`
  ${query(MEDIUM_BREAKPOINT)`
    padding-top: 280px !important;
  `}
`;

const PhilBody = styled.p`
  && {
  }
`;

const TitleWrap = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;

const Items = styled.div`
  && {
    ${query(500)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px -10px;
  `};
  }
`;

const Item = styled.div`
  && {
    max-width: ${pWidth}px;

    ${query(500)`
    flex: 1 0 0%;
    min-width: 340px;
    margin: 0px 10px;
  `};
  }
`;

const Title = styled.h1`
  && {
    text-align: center;
    margin-top: 2rem;
  }
`;

const Head = styled.h3`
  && {
    margin: 0;
    margin-left: 10px;
  }
`;

const Body = styled.p`
  && {
  }
`;

export const pageQuery = graphql`
  query philPhotos {
    mainImage: file(relativePath: { eq: "square/sam-3.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/reception.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/meeting.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    teamImage: file(relativePath: { eq: "philosophy/team.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70, width: 1040) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
  }
`;
